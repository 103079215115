<template>
 <v-stepper v-model="numberStep" :dark="$store.state.corpInfo.darkTheme" max-width="1000" class="mainStepper" :class="[{'light':!$store.state.corpInfo.darkTheme}]">
   <v-stepper-header>
     <v-divider></v-divider>
     <v-stepper-step class="text-uppercase" :complete="numberStep > 1" step="1" color="var(--color-primary)">
      {{ $t('actions.shopDetails') }}
     </v-stepper-step>

     <v-divider></v-divider>

     <v-stepper-step  class="text-uppercase"
        v-show="showUserDataStep===true" :complete="numberStep > 2" step="2" color="var(--color-primary)">
        {{ $t('actions.step.usuario') }}
     </v-stepper-step>

     <v-divider></v-divider>

     <v-stepper-step class="text-uppercase"
      :complete="numberStep > 3" 
      :step="`${showUserDataStep === true ? 3: 2}`" 
      color="var(--color-primary)">
        {{ $t('actions.step.pay') }}
     </v-stepper-step>

     <v-divider></v-divider>
   </v-stepper-header>

   <v-stepper-items>
     <v-stepper-content step="1" color="orange">
       <PurchaseDetailsTypeMap class="mb-12" v-if="vEntorno.events[vEntorno.indexEveSel].event_config == 5"/>
       <PurchaseDetailsType4 class="mb-12" v-if="vEntorno.events[vEntorno.indexEveSel].event_config == 4"/>

       <v-container class="d-flex flex-column flex-md-row flex-lg-row justify-between">
        <v-btn class="mx-auto my-2 order-2 order-md-1 order-lg-1" 
          color="black" dark
          @click="cancelShop"> {{ $t('actions.cancelShop') }} </v-btn>

        <v-btn class="mx-auto my-2 order-1 order-md-2 order-lg-2" 
          color="var(--color-primary)" 
          :dark="$store.state.corpInfo.darkTheme" 
          @click="NextStep(1)"> {{ $t('actions.next') }} </v-btn>
       </v-container>
        
     </v-stepper-content>

     <v-stepper-content :step="`${showUserDataStep === true ? 2: 0}`">
       <Formulario ref="Formulario" 
          v-if="showUserDataStep"
          @ValidationUserForm="ValidationUserForm"
          :editInfo="editUserData"
          @editUserInfo="editUserInfo" />
        <v-container class="d-flex flex-column flex-md-row flex-lg-row justify-between">
        <v-btn class="mx-auto my-2 order-1 order-md-2 order-lg-2" 
          color="var(--color-primary)" 
          :dark="$store.state.corpInfo.darkTheme" 
          @click="NextStep(2)"> 
            {{ $t('actions.next') }} 
        </v-btn>
       </v-container>
       
     </v-stepper-content>

     <v-stepper-content  :step="`${showUserDataStep === true ? 3: 2}`">
      <div v-if="!processExclusive">
        <CardForm ref="CardFormu" 
          @validationCardSuccessful="acceptedCardValid"
          @validationCardDenied="rejectedCardValid"
        />
        <v-container class="d-flex flex-column flex-md-row flex-lg-row justify-between">
         <v-btn class="mx-auto my-2 order-2 order-md-1 order-lg-1" 
          color="black" dark
          @click="cancelShop"> 
            {{ $t('actions.cancelShop') }} 
          </v-btn>
         <v-btn class="mx-auto my-2 order-1 order-md-2 order-lg-2" 
            color="var(--color-primary)" :dark="$store.state.corpInfo.darkTheme" 
            @click="NextStep(3)" 
            :loading="loadingBtn"> 
              {{ $t('actions.next') }} 
          </v-btn>
        </v-container>
      </div>      
      <v-card v-else
        min-height="350"
        class="d-flex justify-center align-center"
      >
        {{ $t('messages.generateorden') }}...
      </v-card>
     </v-stepper-content>
     
   </v-stepper-items>
 </v-stepper>
</template>

<script>
import PurchaseDetailsType4 from "./purchaseDetailsType4.vue";
import PurchaseDetailsTypeMap from "./purchaseDetailsTypeMap.vue";
import Formulario from "../netpay/Formulario.vue";
import CardForm from "../netpay/UserCardForm.vue";
import { USER_DATA } from '@/mixins/user-mixin'
import { PURCHASE_FUNCTIONS } from '@/mixins/purchase-mixin'
// import { PURCHASE_SIMULATION_FUNCTIONS } from '@/mixins/purchaseSimulation-mixin'
import { mapActions } from 'vuex';

export default {
 name: "MainStep",
 components: {
  PurchaseDetailsType4,
  PurchaseDetailsTypeMap,
  Formulario,
  CardForm
 },
 data() {
   return {
     numberStep: 1,
     tokenCard: "",
     editUserData: false,
     showPreview: true,
     fileEmpty: false,
     showUserDataStep: false, 
     loadingBtn : false,
     vEntorno: null,
     processExclusive: false
   };
 },
 mixins: [
  USER_DATA,
  PURCHASE_FUNCTIONS
  // PURCHASE_SIMULATION_FUNCTIONS
 ],
 created() {
  this.vEntorno = this.$store.state.sites;
 },
 mounted() {
  this.processExclusive = this.$store.getters['shopping/getRegularTotal'] == 0;
  this.vEntorno = this.$store.state.sites;
  this.loadingBtn = false
  this.LoadUserData();
 },
 methods: {
    ...mapActions({
      resetEventValues: 'sites/resetEventValues',
      resetSells: 'shopping/resetRegularSell'
    }),
    ValidationUserForm(value) {
      if (value === true) {
        this.numberStep = 3;
        if(this.processExclusive) this.benefitCustomPay()
      } else {
        this.$root.swalAlert(
          "warning",
          "",
          this.$t("messages.checkTheFormData")
        );
        return false;
      }
    },
    LoadUserData() {
      this.getUserDataMix().then(
        (response) => {
        if(response.calle === null || response.calle == "") {
          this.showUserDataStep = true;
          setTimeout(() => {
            this.$refs.Formulario.loadCityAndState();
          }, 200)
        }
      }, 
      (error) => false);
    },
    NextStep(page, processExclusive) {
      switch (page) {
        case 1: 
          this.numberStep = 2;
          if(this.processExclusive && !this.showUserDataStep) this.benefitCustomPay()
          break;        
        case 2:
          this.checkUser();
          break;
        case 3:
          this.$root.loading = true;
          this.$vEntorno.textloading = this.$t('messages.paymentProcess.validateCard'); 
          this.loadingBtn = true
          setTimeout(() => {
            this.SaveUserData();
          }, 150)
          break;
      }
    },
    acceptedCardValid(responseToken, userCardData) {
      this.loadingBtn = false;
      this.tokenCard = responseToken;
      this.Pay(this.tokenCard, userCardData);
    },
    rejectedCardValid() {
      this.$root.loading = false;
      this.loadingBtn = false;
    },
    checkUser() {
      this.$refs.Formulario.Save();
    },
    SaveUserData() {
      this.$refs.CardFormu.ValidateCard();
    },
    GoBack() {
      this.e1 = parseInt(this.e1);
      switch (this.e1) {
        case 1:
          break;
        case 2:
          this.e1 = 1;
          break;
        case 3:
          this.e1 = 2;
          break;
        case 4:
          this.e1 = 3;
          break;
      }
    },
    Pay(tokenCard, cardUserDetails) {
      try {
        this.loadingBtn = true;
        this.$root.loading = true;
        
        let purchaseData = {
          id_corp: this.$root.id_corp,
          id_event: this.vEntorno.eventselect.id_event,
          id_eventd: this.vEntorno.eventselect.id_eventd,
          amount: parseFloat(this.$store.getters['shopping/getRegularTotal']),
          cantidad: parseInt(this.$store.getters['shopping/getNumberRegular']),
          id_place: this.vEntorno.events[0].id_place,
          orden: null,
          event_config: this.vEntorno.events[0].event_config,
          tickets: this.$store.state.shopping.regularSellTickets,
          tokenTem: null,
          credential_id: this.$store.state.sites.credential_id,
          stadisticFlag: this.$store.state.sites.statistics,
          stadisticOptions: this.$store.state.shopping.stadisticsValue
        }
        let dataJSON = {
            transactionType: 'Auth',
            sessionId: tokenCard.deviceFingerPrint,
            deviceFingerPrint: tokenCard.deviceFingerPrint,
            amount: parseFloat(this.$store.getters['shopping/getRegularTotal']),
            description: this.vEntorno.events[0].name["ES"],
            source: tokenCard.token,
            paymentMethod: "card",
            currency: "MXN",
            billing: {
              firstName: cardUserDetails.firstName,
              lastName: cardUserDetails.lastName,
              email: this.$store.state.auth.userData.correo,
              phone: this.$store.state.auth.userData.celular,
              merchantReferenceCode: null,
              address: {
                city: cardUserDetails.city,
                country: cardUserDetails.country,
                postalCode: cardUserDetails.postalCode,
                state: cardUserDetails.state,
                street1: cardUserDetails.street,
                street2: "SN",
              },
            },
            redirect3dsUri: "http://example.com",
            saveCard: 'false',
          };
          
        this.initTransacTokenMix()
        .then((response) => {
          if(response.success === 'TRUE') {
            purchaseData.tokenTem = response.encoded;
            dataJSON.billing.merchantReferenceCode = response.encoded;
            this.startPurchaseMix(purchaseData, dataJSON);
          }
          else {            
            this.$root.loading = false; 
            this.$root.swalAlert('error', "", this.$t("sales.failurePaymentMessages.startNewShopMessage"));
          }
        },
        (error) => {
          this.$root.loading = false;
          if (error.response && error.response.status !== 401) {
            this.$root.swalAlert('error', this.$t("sales.failurePaymentMessages.startNewShopMessage"), error.message);
          } 
          if(error.success === 'FALSE') {
            this.$root.swalAlert('error', this.$t("sales.failurePaymentMessages.startNewShopMessage"), error.message);
          }
        })
        .finally( () => {
          this.loadingBtn = false;
        })

      } catch(e) {
        this.$root.loading = false;
        this.loadingBtn = false;
      }
    },
    benefitCustomPay() {
      try {
        this.loadingBtn = true;
        this.$root.loading = true;
        
        let purchaseData = {
          id_corp: this.$root.id_corp,
          id_event: this.vEntorno.eventselect.id_event,
          id_eventd: this.vEntorno.eventselect.id_eventd,
          amount: parseFloat(this.$store.getters['shopping/getRegularTotal']),
          cantidad: parseInt(this.$store.getters['shopping/getNumberRegular']),
          id_place: this.vEntorno.events[0].id_place,
          orden: null,
          event_config: this.vEntorno.events[0].event_config,
          tickets: this.$store.state.shopping.regularSellTickets,
          tokenTem: null,
          credential_id: this.$store.state.sites.credential_id,
          stadisticFlag: this.$store.state.sites.statistics,
          stadisticOptions: this.$store.state.shopping.stadisticsValue
        }

        this.initTransacTokenMix()
        .then((response) => {
          if(response.success === 'TRUE') {
            purchaseData.tokenTem = response.encoded;
            this.paymentExclusiveMix(purchaseData, this.processExclusive);
          } 
          else {
            this.loadingBtn = true;
            this.$root.loading = true;
            this.$root.swalAlert('error', "", this.$t('sales.netpayErrorMessages.payError'));
          } 
        },
        (error) => {
          if (error.response && error.response.status !== 401) {
            this.$root.swalAlert('error', error.message);
          } 
          if(error.success === 'FALSE') {
            this.$root.swalAlert('error', error.message);
          }
        })
        .finally( () => {
          this.loadingBtn = false;
        })
      } catch(e) {
        this.loadingBtn = false;
      }
    },
    editUserInfo(val) {
      this.editUserData = true;
      if(val === 1) {
        this.fileEmpty = true;
      }
    },
    cancelShop() {
      this.$root.post("/ServiceClient/CancelReservation", {
        transacToken: this.$store.state.shopping.reserveToken
      }).then(
        () => {
          this.$root.resetShoppingTime()
          this.resetEventValues()
          this.resetSells()
          this.$router.push({
            name: 'eventPreview',
            query: { 
              e: this.vEntorno.events[0].name[this.$root.getTextlang()], 
              v: this.vEntorno.events[0].id_event
            }
          });
        }
     );
    }
 },
};
</script>

<style scoped>
.mainStepper {
 overflow-y: auto;
 max-height: calc(100vh - 95px );
 background-color: #00000080 !important;
}
.light {
  background-color: #ffffffa6 !important;
}
</style>