<template>
  <div class="ClientContainer" v-if="$store.state.shopping.regularSellTickets.length > 0">
    <div style="width: 100%; height: 100%; position: relative;" :style="$store.state.corpInfo.backgroundImage?styleBack:styleNoBack">
      <div style="{position: absolute; z-index: 1;}"></div>
      <div class="body-con" align="center">
        <div class="header-container">
          <v-toolbar dark class="subheader" elevation="0" max-width="1300px">
            <v-container class="d-flex flex-row align-center px-0">
              <v-col cols="4">
                <img v-if="$store.state.corpInfo.logo"
                :src="$root.UrlCorpImages + $store.state.corpInfo.logo"
                class="HeaderImg" />
              </v-col>
              <v-col cols="4" class="headertext" :class="[{'white--text':$store.state.corpInfo.darkTheme}, {'black--text':!$store.state.corpInfo.darkTheme}]">
                <div>{{ vEntorno.events[vEntorno.indexEveSel].name[$root.getTextlang()] }}</div>
                <div>
                  {{ vEntorno.eventselect.dayn }}
                  {{ vEntorno.eventselect.fecev }}
                  {{ vEntorno.eventselect.mesev }},
                  {{ vEntorno.eventselect.time_event }}, {{ vEntorno.events[vEntorno.indexEveSel].place }}
                </div>
              </v-col>
              <v-col cols="4">
                <div class="time-style">
                  <v-progress-circular 
                   :rotate="$root.timelimit" 
                   :size="55" 
                   :width="4" 
                   :value="$root.value2" 
                   :color="$store.state.corpInfo.darkTheme?'white':'black'">
                    {{ Tranform($root.value) }}
                  </v-progress-circular>
                </div>
              </v-col>
            </v-container>
          </v-toolbar>
        </div>        
        <MainStep class="my-4 mx-2"/>
      </div>
    </div>
  </div>
</template>

<script>
import MainStep from "../components/Sell/MainStep.vue";

export default {
  name: "Sell",
  components: {
    MainStep,
  },
  data() {
    return {
      comprando: true,
      pasoX: false,
      paso01: false,
      tickets: [],
      vEntorno: null,
      styleBack:{
        backgroundImage: `url(${this.$root.UrlCorpImages + this.$store.state.corpInfo.backgroundImage})`,
        'background-size': 'cover',
        'background-position': '50% 50%',
        'background-color': 'var(--color-bg-others)',
        'background-blend-mode': 'multiply'
      },
      styleNoBack: {
        'background': `linear-gradient(
          to bottom,
          ${this.$store.state.corpInfo.darkTheme ? 
          '#000000 0%, var(--color-bg-others) 100%':'#E2E6EF 0%, var(--color-bg-others) 100%'}
        )`
      }
    };
  },
  created() {
    this.$root.token = this.$store.getters['auth/getUserS']?.token_cliente
    this.vEntorno = this.$store.state.sites;
  },
  beforeMount() {
    this.begin();
  },
  mounted() {    
    this.$root.value2 = 0;
    this.$root.value = 0;
  },
  methods: {
    async begin() {
      this.vEntorno = this.$store.state.sites;
      
      if(this.$store.state.shopping.regularSellTickets.length === 0) {
        this.$root.finish();
        return false;
      }
    },
    Tranform(num) {
      let sec =
        ((this.$root.timelimit - num) % 60 < 10 ? "0" : "") +
        ((this.$root.timelimit - num) % 60);
      let min =
        (parseInt((this.$root.timelimit - num) / 60) < 10 ? "0" : "") +
        parseInt((this.$root.timelimit - num) / 60);
      return min + ":" + sec;
    },
  },
};
</script>

<style scoped>
.headertext {
  padding-top: 19px;
  font-weight: bold;
}

.HeaderImg {
  max-width: 170px;
  opacity: 1;
  margin-top: 8px
}

.v-progress-circular {
  margin: 1rem;
  height: 55px !important;
}

.header-container {
  max-height: 200px !important;
  background: linear-gradient(207deg,
      var(--color-primary-light) 0%,
      var(--color-primary) 45%,
      var(--color-primary-light1) 99%) !important;
}
.subheader {
  max-width: 1300px;
  background: transparent !important;
}

.time-style {
  font-size: 12px;
}
.body-con{
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  width: 100%;
}
.ClientContainer {
  height: 100vh;
  width: 100vw;
  position: relative;
}
@media (max-width: 800px) {
  .headertext {
    font-size: 10px;
    margin-top: -7px;
    line-height: 12px;
  }
  .HeaderImg {
    max-width: 90px;
  }
}
</style>