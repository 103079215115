<template>
 <v-card class="mx-auto px-0 px-md-5 px-lg-5 card-style" max-width="700" elevation="0">
   <div style="padding-top: 30px">
     <h6>
       {{ $t("messages.msgConfirmation1") }}
     </h6>
   </div>
   <v-row>
     <v-col cols="12"></v-col>
     <v-col cols="12">
       <img
         :src="$root.pathevens + vEntorno.events[vEntorno.indexEveSel].img"
         class="MyImg"
       />
       <div
         align="left"
         style="padding: 12px 10px 0px 10px; font-size: 11px"
         :class="[{'white--text':$store.state.corpInfo.darkTheme}, {'black--text':!$store.state.corpInfo.darkTheme}]"
       >
         <strong class="details-shop">{{ $t('actions.shopDetails') }}</strong>
       </div>
       <hr class="mb-2" style="border: 1px solid var(--color-primary);"/>
       <div align="left" style="padding: 0px 10px 0px 10px; font-size: 12px">
         <table style="width: 100%; font-size: 18px">
           <tr>
             <td>
               <v-icon style="color: var(--color-primary); transform: rotate(135deg)"
                 >mdi-ticket-outline</v-icon
               >x{{ getNumberTickets() }}
               {{ $t("actions.cardinfo.tickets") }}
             </td>
           </tr>
         </table>
         <div style="padding: 10px 10px 10px 0px; overflow:auto">
           <table style="width: 100%" class="tablax">
             <thead>
               <tr :style="$store.state.corpInfo.darkTheme?styleHeaderDark:styleHeaderLight">
                 <th>
                   <strong>
                    {{ $t("actions.cardinfo.section") }}</strong>
                 </th>
                 <th>
                   <strong>{{ $t("actions.cardinfo.row") }}</strong>
                 </th>
                 <th>
                   <strong>{{ $t("actions.cardinfo.colum") }}</strong>
                 </th>
                 <th>
                   <strong>{{ $t("actions.cardinfo.seat") }}</strong>
                 </th>
                 <th>
                   <strong>{{ $t("actions.cardinfo.amount") }}</strong>
                 </th>
                 <th>
                   <strong>{{ $t("actions.cardinfo.unitPrice") }}</strong>
                 </th>
                 <th>
                   <strong>{{ $t("actions.cardinfo.subtotal") }}</strong>
                 </th>
               </tr>
             </thead>
               <tbody>
                 <tr
                 v-for="(item, index) in $store.state.shopping.regularSellTickets"
                 :key="'bolet' + index"
               >
                 <td :style="$store.state.corpInfo.darkTheme?styleBodyDark:styleBodyLight">{{ item.section }}</td>
                 <td :style="$store.state.corpInfo.darkTheme?styleBodyDark:styleBodyLight">{{ item.rowx ? item.rowx : '-' }}</td>
                 <td :style="$store.state.corpInfo.darkTheme?styleBodyDark:styleBodyLight">{{ item.columnx ? item.columnx : '-' }}</td>
                 <td :style="$store.state.corpInfo.darkTheme?styleBodyDark:styleBodyLight">{{ item.title ? item.title : '-' }}</td>
                 <td :style="$store.state.corpInfo.darkTheme?styleBodyDark:styleBodyLight">
                  {{ item.cantidad ? item.cantidad : 1 }}</td>
                 <td style="text-align: right;" :style="$store.state.corpInfo.darkTheme?styleBodyDark:styleBodyLight">
                    MX ${{ $root.formatPrice(item.price) }}
                  </td>
                 <td style="text-align: right;" :style="$store.state.corpInfo.darkTheme?styleBodyDark:styleBodyLight">
                    MX ${{ item.cantidad ? $root.formatPrice(item.price * item.cantidad) : $root.formatPrice(item.price) }}
                  </td>
               </tr>
             </tbody>
           </table>
         </div>
         <div
           style="width: 100%; padding: 10px 10px 10px 10px; font-size: 18px"
         >
           <table style="width: 100%">
             <tr>
               <td align="right">
                 {{$t("actions.cardinfo.total")}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; MX ${{
                   $root.formatPrice(getTotal())
                 }}
               </td>
             </tr>
           </table>
         </div>
       </div>
     </v-col>
   </v-row>
 </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
 name: "PurchaseDetailsTypeMap",
 components: {},
 data() {
   return {
    img: null,
    vEntorno: null,
    Inputnumeric : null,
    Boletos: [],
    precio: 0,
    styleHeaderDark: {
      'background': 'black',
      'color': '#D5D5D5'
    },
    styleHeaderLight: {
      'background': 'white',
      'color': 'black'
    },
    styleBodyDark: {
      'background': '#252525',
      'color': '#D4D4D4'
    },
    styleBodyLight: {
      'background': '#F0F0F0',
      'color': '#3B3B3B'
    },
   };
 },
 created() {
  this.vEntorno = this.$store.state.sites;
 },
 mounted() {
   this.load();
 },
 methods: {  
  ...mapGetters({
    getNumberTickets: 'shopping/getNumberRegular',
    getTotal: 'shopping/getRegularTotal'
  }),
  load() {
    this.vEntorno = this.$store.state.sites;
    this.img = this.vEntorno.events[this.vEntorno.indexEveSel].img;
    if (this.vEntorno.tickets.length > 0) {
      let precio = 0;
      for (var x = 0; x < this.vEntorno.tickets.length; x++) {
        precio = precio + parseFloat(this.vEntorno.tickets[x].price);
      }
      this.precio = precio.toFixed(2);
    }
  },
 },
};
</script>

<style scoped>
.details-shop {
  filter: brightness(1.7);
}
.MyImg{
 max-width: 400px;
 max-height: 300px;
}
.card-style {
  background-color: transparent;
}
.tablax th {
 text-align: center;
 padding: 3px 10px;
 font-weight: normal;
}

.tablax td {
 text-align: center;
 padding: 2px 10px;
 font-weight: normal;
}

.tablax tr:nth-child(odd) {
 background-color: white;
}

.tablax tr:nth-child(even) {
 background-color: rgb(241, 241, 241);
}

.pointman:hover {
 cursor: pointer;
 opacity: 0.7;
}
.Remember label {
 top: 6px !important;
}

.MyCardX {
 padding: 20px;
 height: calc(100% - 105px);
 overflow: auto;
}

.pointman:hover {
 opacity: 0.5;
}
@media (max-width: 794px) {
 .MyImg{
   max-width: 100%;
   max-height: 100px;
 }
}
</style>